import { OptionI } from "component/SelectWithData";
import { ECarePlanFrequency } from "enums/notification";

export const frequencyOptions = [
  {
    label: "毎週",
    value: ECarePlanFrequency.WEEKLY,
  },
  {
    label: "1週間おき",
    value: ECarePlanFrequency.OTHER_WEEK,
  },
  {
    label: "2週間おき",
    value: ECarePlanFrequency.TWICE_WEEK,
  },
];

export const shiftOptions: OptionI[] = [
  {
    label: "早",
    value: "early_shift",
  },
  {
    label: "遅",
    value: "late_shift",
  },
  {
    label: "夜",
    value: "night_shift",
  },
  {
    label: "明",
    value: "morning_shift",
  },
  {
    label: "日1",
    value: "day_shift_1",
  },
  {
    label: "日2",
    value: "day_shift_2",
  },
  {
    label: "日3",
    value: "day_shift_3",
  }
];

export const shiftOldOptions: OptionI[] = [
  {
    label: "日勤4",
    value: "day_shift_4",
  },
  {
    label: "日勤5",
    value: "day_shift_5",
  }
];

export const defaultLongTermGoals = [
  {
    name: "long_term_goal_1",
    label: "長期目標1",
  },
];

export const defaultShortTermGoals = [
  {
    name: "short_term_goal_1",
    label: "短期目標1",
  },
];
