import { Flex } from "@chakra-ui/react";
import { TablelStyled, Styled } from "./styled";
import { forwardRef, useEffect } from "react";
import { IItemPaymentReceiptNotice } from "types/admin";
import dayjs, { Dayjs } from "dayjs";
import {
  DATE_ERA_FORMAT,
  DATE_ERA_FORMAT_YEAR_MONTH,
  DATE_FORMAT_2,
} from "constant/date";
import moment from "moment";
import { formatPhoneNumber } from "utils/formats";
import { get } from "lodash";
import { SERVICE_CONTENTS } from "constant";

interface IProps {
  issueDate?: Dayjs;
  receiptDate?: Dayjs;
  currentDate?: Dayjs;
  dataPrint: IItemPaymentReceiptNotice[];
  setIsReadyPrint: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationReceiptAmount = (props: IProps, ref: any) => {
  const { issueDate, receiptDate, currentDate, dataPrint, setIsReadyPrint } =
    props;
  useEffect(() => {
    setIsReadyPrint(true);
  }, []);

  const getText =  (obj: any, path: string) => {
    return get(obj, path) ?? ""
  }

  const issueDateString = moment(
    dayjs(issueDate).format(DATE_FORMAT_2)
  ).format(DATE_ERA_FORMAT.replaceAll(" ", ""));

  const receiptDateString = moment(
    dayjs(receiptDate).format(DATE_FORMAT_2)
  ).format(DATE_ERA_FORMAT.replaceAll(" ", ""));

  const monthSelected = moment(
    dayjs(currentDate).format(DATE_FORMAT_2)
  ).format(DATE_ERA_FORMAT_YEAR_MONTH);
  
  return (
    <Styled  ref={ref} hidden>
      {dataPrint.map((item) => {
        const { client, recipient, profile } = item;

        const keyAsServiceContent = recipient?.service_content as keyof typeof SERVICE_CONTENTS
        const serviceContentText = SERVICE_CONTENTS[keyAsServiceContent] || ""

        return (
          <div
            key={client.id}
            className="w-[210mm] p-[6mm] h-[297mm] bg-white text-[4mm] break-inside"
          >
            <Flex justifyContent="space-between">
              <div>
                <p className="">〒 {client.zipcode}</p>
                <p className="max-w-[140mm]">
                  {getText(client, "district") +
                    getText(client, "city") +
                    getText(client, "street") +
                    getText(client, "building_name")}
                </p>
                <p className=" border-b inline-block leading-[14px] border-[black] mt-4">
                  <span>{client.last_name}</span>
                  <span className="mx-5">{client.first_name}</span>
                  <span>様</span>
                </p>
              </div>

              <div>
                <p className="">〒 {profile.zipcode}</p>
                <p className="">
                  {getText(profile, "district") + getText(profile, "city")}
                </p>
                <p className="">{profile.street}</p>

                <p className=" mt-4 leading-[14px] border-[black] border-b inline-block">
                  {profile.facility_name}
                </p>
                <p className="">
                  TEL : {formatPhoneNumber(profile.mobile_phone || "")}
                </p>
                <p className="">FAX : {formatPhoneNumber(profile.fax || "")}</p>
              </div>
            </Flex>

            <div className="font-bold text-3xl text-center mt-[13mm] mb-[18mm]">
              代理受領額通知書
            </div>
            <div>
              <div className="text-end mb-[4mm]">{issueDateString} 発行</div>
              <TablelStyled>
                <tr className="h-[14mm]">
                  <td className="text-center w-[55mm]">受給者証番号</td>
                  <td >{recipient?.recipient_code}</td>
                </tr>
                <tr className="h-[14mm]">
                  <td className="text-center w-[55mm]">
                    支給決定障害者等
                    <br />
                    氏名
                  </td>
                  <td>
                    {client.last_name} {client.first_name}
                  </td>
                </tr>
                <tr className="h-[14mm]">
                  <td className="text-center w-[55mm]">
                    支給決定に係る <br />
                    障害児氏名
                  </td>
                  <td></td>
                </tr>
              </TablelStyled>
            </div>

            <div className="text-1xl text-center my-[10mm]">
              下記のとおり、障害福祉サービスに要した費用を代理受領しましたのでお知らせします。
            </div>
            <div>
              <TablelStyled>
                <tr className="h-[14mm]">
                  <td className="text-center w-[55mm]" colSpan={2}>
                    サービス提供年月
                  </td>
                  <td>{monthSelected}</td>
                </tr>
                <tr className="h-[14mm]">
                  <td className="text-center w-[55mm]" colSpan={2}>
                    市 町 村 名
                  </td>
                  <td>{client.city}</td>
                </tr>
                <tr className="h-[14mm]">
                  <td className="text-center w-[55mm]" colSpan={2}>
                    障害福祉サービス
                  </td>
                  <td>{serviceContentText}</td>
                </tr>
                <tr className="h-[14mm]">
                  <td className="text-center w-[55mm]" colSpan={2}>
                    受 領 日 付
                  </td>
                  <td>{receiptDateString}</td>
                </tr>
                <tr className="h-[14mm]">
                  <td className="text-center w-[55mm]" colSpan={2}>
                    受 領 金 額
                  </td>
                  <td>
                    <Flex alignItems={"center"}>
                      <b className="text-2xl">579,683</b>円 (
                      <div className="border w-[4mm] h-[4mm] inline-flex justify-center items-center  rounded-[100%]  ">
                        1
                      </div>
                      <span className="mx-1">-</span>
                      <div className="border w-[4mm] h-[4mm] inline-flex justify-center items-center  rounded-[100%] ">
                        2
                      </div>
                      )
                    </Flex>
                  </td>
                </tr>
                <tr className="h-[14mm]">
                  <td className="text-center w-[14mm] !p-0" rowSpan={2}>
                    内 <br /> 訳
                  </td>
                  <td colSpan={1} className="text-center w-[41mm]">
                    サービスに要し た費用の全体の 額{" "}
                    <span className="border w-[4mm] h-[4mm] inline-flex justify-center items-center  rounded-[100%]">
                      1
                    </span>
                  </td>
                  <td>579,683円</td>
                </tr>
                <tr className="h-[14mm]">
                  <td colSpan={1}>
                    利用者負担額{" "}
                    <span className="border w-[4mm] h-[4mm] inline-flex justify-center items-center  rounded-[100%]">
                      2
                    </span>
                  </td>
                  <td>0円</td>
                </tr>
              </TablelStyled>
            </div>
          </div>
        );
      })}
    </Styled>
  );
};

export default forwardRef(NotificationReceiptAmount);
