import styled from "@emotion/styled";

export const TablelStyled = styled.table`
  * {
    boder-color: black;
    word-break: break-word;
  }

  & {
    width: 100%;
    border: 1mm solid black;
  }

  th,
  td {
    border: 0.5mm solid;
    border: 0.5mm solid;
  }
`;

export const Styled = styled.div`
  * {
    border-color: black;
  }
  .text-orientation-right {
    writing-mode: vertical-lr;
    text-orientation: upright;
  }
`;
