import { FormInstance } from "antd";
import {
  DATE_FORMAT,
  HOURS_PER_DAY,
  MONTH_FORMAT,
  TIME_FORMAT,
  formatDate,
} from "constant/date";
import dayjs, { ConfigType, Dayjs } from "dayjs";

export function convertDateArrayToBooleanArray(
  dateArray: string[],
  month: string,
  lastMonth: boolean
) {
  // Khởi tạo một mảng boolean rỗng
  let booleanArray = [];
  // Lấy số ngày trong tháng bằng cách sử dụng dayjs
  let daysInMonth = dayjs(month)
    .add(lastMonth ? 1 : 0, "month")
    .daysInMonth();
  // Chuyển đổi các ngày trong mảng ngày sang cùng định dạng với ngày hiện tại
  let formattedDateArray = dateArray.map((d) => dayjs(d).format(DATE_FORMAT));
  // Duyệt qua từng ngày trong tháng
  for (let i = 1; i <= daysInMonth; i++) {
    // Tạo một chuỗi ngày theo định dạng yyyy/mm/dd
    let date = month + "/" + i;
    // Chuyển đổi ngày đó sang cùng định dạng với các ngày trong mảng ngày
    let formattedDate = dayjs(date).format(DATE_FORMAT);
    // Kiểm tra xem ngày đó có nằm trong mảng ngày hay không
    if (formattedDateArray.includes(formattedDate)) {
      // Nếu có, thêm giá trị true vào mảng boolean
      booleanArray.push(true);
    } else {
      // Nếu không, thêm giá trị false vào mảng boolean
      booleanArray.push(false);
    }
  }
  // Trả về mảng boolean
  return booleanArray;
}

// function convertDateArrayToBooleanArrayStaff(
//   dateArray: string[],
//   month: string,
//   lastMonth: boolean
// ) {
//   // Khởi tạo một mảng boolean rỗng
//   let booleanArray = [];
//   // Lấy số ngày trong tháng bằng cách sử dụng dayjs
//   let daysInMonth = dayjs(month)
//     .add(lastMonth ? 1 : 0, "month")
//     .daysInMonth();
//   // Chuyển đổi các ngày trong mảng ngày sang cùng định dạng với ngày hiện tại
//   let formattedDateArray = dateArray.map((d) => dayjs(d).format(DATE_FORMAT));
//   // Duyệt qua từng ngày trong tháng
//   for (let i = 1; i <= daysInMonth; i++) {
//     // Tạo một chuỗi ngày theo định dạng yyyy/mm/dd
//     let date = month + "/" + i;
//     // Chuyển đổi ngày đó sang cùng định dạng với các ngày trong mảng ngày
//     let formattedDate = dayjs(date).format(DATE_FORMAT);
//     // Kiểm tra xem ngày đó có nằm trong mảng ngày hay không
//     if (formattedDateArray.includes(formattedDate)) {
//       // Nếu có, thêm giá trị true vào mảng boolean
//       booleanArray.push(true);
//     } else {
//       // Nếu không, thêm giá trị false vào mảng boolean
//       booleanArray.push(false);
//     }
//   }
//   // Trả về mảng boolean
//   return booleanArray;
// }

export interface IFEData {
  id: number;
  patient_id: number;
  comprehensive_aid_policy: string;
  individual_family_intention: string;
  aid_purpose: string;
  short_term_date?: Dayjs[];
  long_term_date?: Dayjs[];
  remarks: string;
  year_month: string;
  improvement_nursing_care_plans: any[];
  schedules: IFEData_Schedule[];
}

export interface IFEStaffData {
  id: number;
  patient_id: number;
  comprehensive_aid_policy: string;
  individual_family_intention: string;
  aid_purpose: string;
  short_term_date?: Dayjs[];
  long_term_date?: Dayjs[];
  long_term_goal_one?: string;
  long_term_goal_two?: string;
  long_term_goal_three?: string;
  long_term_goal_four?: string;
  short_term_goal_one?: string;
  short_term_goal_two?: string;
  short_term_goal_three?: string;
  short_term_goal_four?: string;
  remarks: string;
  year_month: string;
  improvement_nursing_care_plans: any[];
}

export interface IBEData {
  id: number;
  patient_id: number;
  comprehensive_aid_policy: string;
  individual_family_intention: string;
  aid_purpose: string;
  start_long_term?: string;
  end_long_term?: string;
  start_short_term?: string;
  end_short_term?: string;
  remarks: string;
  year_month: string;
  improvement_nursing_care_plans: any[];
  schedules: IBEData_Schedule[];
  plan_long_terms?: {
    created_at: string;
    id: number;
    nursing_care_plan_id: number;
    plan_type: string;
    term_goal: string;
    updated_at: string;
  }[];
  plan_short_terms?: {
    created_at: string;
    id: number;
    nursing_care_plan_id: number;
    plan_type: string;
    term_goal: string;
    updated_at: string;
  }[];
}
interface IFEData_Schedule {
  shift_dates: boolean[];
  service_id?: number;
  service_type_id?: number;
  time: Dayjs[];
  frequency?: string;
}

interface IBEData_Schedule {
  service_id: number;
  service_type_id: number;
  start_time: string;
  end_time: string;
  shift_type: string;
  schedule_routines?: {
    regis_day: number;
    schedule_id: number;
    updated_at: string;
  }[];
}

const changeTimeZone = (date: ConfigType, offset: number) => {
  return dayjs(date)
    .set("hour", dayjs(date).get("hour") + offset)
    .toISOString();
};

const getShiftTypeText = (shiftType: string) => {
  if(shiftType === 'day_shift_4') return '日4'
  if(shiftType === 'day_shift_5') return '日5'
  return shiftType
}

const getShiftType = (shiftType?: string) => {
  if(shiftType === '日4') return 'day_shift_4'
  if(shiftType === '日5') return 'day_shift_5'
  return shiftType
}

export const toFEData = (
  data: IBEData,
  year_month: Dayjs,
  lastMonth: boolean,
  form: FormInstance
) => {
  const planLongTerms = data?.plan_long_terms?.reduce((acc, item, index) => {
    Object.assign(acc, {
      [`long_term_goal_${index + 1}`]: item?.term_goal,
    });

    return acc;
  }, {});

  const planShortTerms = data?.plan_short_terms?.reduce((acc, item, index) => {
    Object.assign(acc, {
      [`short_term_goal_${index + 1}`]: item?.term_goal,
    });

    return acc;
  }, {});

  const longTermGoals = data?.plan_long_terms?.map((_, index) => ({
    name: `long_term_goal_${index + 1}`,
    label: `長期目標${index + 1}`,
  }));

  const shortTermGoals = data?.plan_short_terms?.map((_, index) => ({
    name: `short_term_goal_${index + 1}`,
    label: `短期目標${index + 1}`,
  }));
  const newData = {
    ...data,
    long_term_date: [
      data.start_long_term && dayjs(data.start_long_term),
      data.end_long_term && dayjs(data.end_long_term),
    ],
    short_term_date: [
      data.start_short_term && dayjs(data.start_short_term),
      data.end_short_term && dayjs(data.end_short_term),
    ],
    id: lastMonth ? form.getFieldValue("id") : data.id,
    improvement_nursing_care_plans: data.improvement_nursing_care_plans.length
      ? data.improvement_nursing_care_plans
      : [{}],
    ...planLongTerms,
    ...planShortTerms,
    schedules: data.schedules.map((schedule) => {
      const shiftDates: boolean[] = [];

      schedule?.schedule_routines?.forEach((item) => {
        if (item.regis_day === 0) {
          shiftDates[6] = true;
        }

        shiftDates[item.regis_day - 1] = true;
      });

      const startHour = dayjs(schedule?.start_time)?.get("seconds");

      const endHour = dayjs(schedule?.end_time)?.get("seconds");
      return {
        ...schedule,
        shift_type: getShiftTypeText(schedule.shift_type),
        time: [
          dayjs(
            formatDate(changeTimeZone(schedule.start_time, 2), TIME_FORMAT),
            TIME_FORMAT
          ),
          dayjs(
            formatDate(changeTimeZone(schedule.end_time, 2), TIME_FORMAT),
            TIME_FORMAT
          ),
        ],
        start_hour:
          startHour > HOURS_PER_DAY ? startHour - HOURS_PER_DAY : startHour,
        start_minute: dayjs(schedule?.start_time).utc()?.get("minutes"),
        end_hour: endHour > HOURS_PER_DAY ? endHour - HOURS_PER_DAY : endHour,
        end_minute: dayjs(schedule?.end_time).utc()?.get("minutes"),
        shift_dates: shiftDates,
      };
    }),
  };

  return { newData, longTermGoals, shortTermGoals };
};

export const toFEStaffData = (
  data: IBEData,
  year_month: Dayjs,
  lastMonth: boolean,
  form: FormInstance
) => {
  const newData = {
    ...data,
    long_term_date: [
      data.start_long_term && dayjs(data.start_long_term),
      data.end_long_term && dayjs(data.end_long_term),
    ],
    short_term_date: [
      data.start_short_term && dayjs(data.start_short_term),
      data.end_short_term && dayjs(data.end_short_term),
    ],
    id: lastMonth ? form.getFieldValue("id") : data.id,
    improvement_nursing_care_plans: data.improvement_nursing_care_plans.length
      ? data.improvement_nursing_care_plans
      : [{}],
    schedules: data.schedules.map((schedule) => ({
      ...schedule,
      time: [
        dayjs(schedule.start_time, TIME_FORMAT),
        dayjs(schedule.end_time, TIME_FORMAT),
      ],
    })),
  };
  return newData as IFEStaffData;
};

const filterPlanTerms = (planTerms: string[]) => {
  return planTerms?.filter((item) => {
    if (planTerms.length === 1 && !item) {
      return true;
    }

    if (!item) {
      return false;
    }

    return true;
  });
};

export const toBEData = (
  data: IFEData,
  patient_id: string | undefined,
  month: Dayjs
) => {
  const {
    comprehensive_aid_policy,
    individual_family_intention,
    aid_purpose,
    remarks,
  } = data;

  const { longTerms, shortTerms } = Object.entries(data)?.reduce(
    (
      acc: {
        longTerms: Array<string>;
        shortTerms: Array<string>;
      },
      [key, value]
    ) => {
      if (key.includes("long_term_goal")) {
        acc.longTerms.push(value);
      }

      if (key.includes("short_term_goal")) {
        acc.shortTerms.push(value);
      }

      return acc;
    },
    {
      longTerms: [],
      shortTerms: [],
    }
  );

  const newData = {
    start_short_term: data.short_term_date?.[0],
    end_short_term: data.short_term_date?.[1],
    start_long_term: data.long_term_date?.[0],
    end_long_term: data.long_term_date?.[1],
    patient_id: patient_id || 0,
    year_month: month.format(MONTH_FORMAT),
    improvement_nursing_care_plans: data.improvement_nursing_care_plans.filter(
      (i: any) => i.treatment_improvement_id
    ),
    schedules: data.schedules.map((item: any) => {
      return {
        id: item.id,
        service_id: item.service_id,
        service_type_id: item.service_type_id,
        start_time: changeTimeZone(item?.time?.[0], -2),
        end_time: changeTimeZone(item?.time?.[1], -2),
        start_time_format: formatDate(item?.time?.[0], TIME_FORMAT),
        end_time_format: formatDate(item?.time?.[1], TIME_FORMAT),
        schedule_routines: item?.shift_dates?.map(
          (checkedDate: boolean, index: number) => {
            return {
              regis_day: index === 6 ? 0 : index + 1,
              checked: checkedDate ?? false,
            };
          }
        ),
        frequency: item?.frequency,
        shift_type: getShiftType(item?.shift_type),
      };
    }),
    long_terms: filterPlanTerms(longTerms),
    short_terms: filterPlanTerms(shortTerms),
    comprehensive_aid_policy,
    individual_family_intention,
    aid_purpose,
    remarks,
  };

  return newData;
};
