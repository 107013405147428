import dayjs from "dayjs";
import TableStyled from "./styled";
import ShiftCard from "./components/ShiftCard/";
import { ButtonOutline, ButtonSolid } from "component/button";
import DateChanger from "../Shift/components/DateChanger";
import { MONTH_FORMAT, DATE_FORMAT } from "constant/date";
import { useEffect, useMemo, useState, useRef } from "react";
import MolalSchedule from "./components/Modal";
import { EMPLOYMENT_TYPE_OPTIONS, ROLE } from "constant";
import {
  SelectedItem,
  ShiftManagement,
  ShiftType,
  TypeDataSubmit,
  TypeDataSubmitItem,
} from "./type";
import apiFactory from "api";
import { useQuery } from "react-query";
import { TableColumnsType } from "antd";
import { Shift } from "page/ManageShiftStaff/type";
import { SHIFT_TYPES } from "constant";
import { toast } from "react-toastify";
import { Flex } from "@chakra-ui/react";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { isEmpty, uniqueId } from "lodash";
import useResponsive from "hook/useResponsive";

const CLASS_SELECTED_CELL = "!bg-red-200";

const ManageShiftStaff = ({ role }: { role?: string }) => {
  const { id : userId } = useSelector((state: RootState) => state.user);
  const isStaff = role === ROLE.STAFF;
  const api = isStaff
    ? apiFactory.staffShiftManagementStaffs
    : apiFactory.adminShiftManagementStaffs;

  const { isMobile, isTablet, windowWidth } = useResponsive();

  const [yearMonth, setYearMonth] = useState(dayjs().format(MONTH_FORMAT));
  const daysInMonth = dayjs(yearMonth).daysInMonth();
  const [disabled, setDisabled] = useState(true);
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const refPreRowSelected = useRef<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasData, setHasData] = useState(false)
  const [otherStaffHasData, setOtherStaffHasData] = useState(false) // apply for staff role
  const [nameNurseSelecting, setNameNurseSelecting] = useState("")

  const { data, isLoading, isFetching, refetch } = useQuery<{
    data: ShiftManagement[];
  }>({
    queryKey: [yearMonth],
    queryFn: () => api.getList({ year_month: yearMonth }),
    keepPreviousData: true,
  });

  const [preDataSubmit, setPreDataSubmit] = useState<TypeDataSubmit>({});

  const daysArray = useMemo(() => {
    let result = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = dayjs(`${yearMonth}/${day}`);
      result.push({
        dayOfMonth: day,
        dayOfWeek: date.locale("ja").format("dd"),
        date: date.format(DATE_FORMAT),
      });
    }
    return result;
  }, [yearMonth]);

  const dataSource = useMemo(() => {
    let isOtherStaffHasData = false
    let isHasData = false
  
    if (isStaff) {
      // prepare for data staff
      const result: any = [];
      data?.data?.forEach((item) => {
        const { id, family_name, shifts, nurse_code, employment_type } = item; // id = nurseId
        //if (!shifts && id !== userId) return;

        const dataSubmitArray = preDataSubmit[id]
          ? Object.values(preDataSubmit[id])
          : [];
        const shiftsData: {
          [key: string]: Pick<Shift, "shift_type" | "date" | "nurse_id"> | null;
        } = {};

        if (shifts) isHasData = true;

        shifts?.forEach((item) => {
          const date = dayjs(item.date);
          const dayOfMonth = date.date();
          shiftsData["day" + dayOfMonth] = item;
        });

        dataSubmitArray.forEach((item) => {
          const { isDelete, shiftType, nurseId } = item;
          const date = dayjs(item.date);
          if (!date.isSame(yearMonth, "month")) return;

          const dayOfMonth = date.date();
          if (isDelete) {
            shiftsData["day" + dayOfMonth] = null;
          } else {
            shiftsData["day" + dayOfMonth] = {
              shift_type: shiftType,
              nurse_id: nurseId.toString(),
              date: item.date,
            };
          }
        });

        const newItem = {
          key: id,
          id,
          family_name,
          nurse_code,
          employment_type,
          ...shiftsData,
        };

        if (id === userId) {
          result.unshift(newItem);
        } else {
          if (shifts) isOtherStaffHasData = true;
          result.push(newItem);
        }
      });
      setOtherStaffHasData(isOtherStaffHasData)
      setHasData(isHasData)
      if(!isOtherStaffHasData) return result[0] ? [result[0]] : []
      return result
    }

   
    // prepare for data master(admin)
    const result: any[] = [];
    // const checkShiftDeleteAll = (shifts: Shift[] | null, nurseId: number) => {
    //   const itemPreDataSubmit = preDataSubmit[nurseId]
    //   if(!shifts) {
    //     if(!itemPreDataSubmit) return true
    //     return !Object.values(itemPreDataSubmit).some(item => !item.isDelete)
    //   }
   
    //   if(!itemPreDataSubmit) return false
    //   return !shifts.some(shift => {
    //     const { date } = shift;
    //     return !itemPreDataSubmit[date] || !itemPreDataSubmit[date].isDelete
    //   })
     
    // }

    data?.data?.forEach((item) => {
      const { id, family_name, shifts, nurse_code, employment_type } = item; // id = nurseId
      // if (!shifts && !preDataSubmit[id])  return;

      //if (checkShiftDeleteAll(shifts, id))  return;

      const dataSubmitArray = preDataSubmit[id]
        ? Object.values(preDataSubmit[id])
        : [];
      const shiftsData: {
        [key: string]: Pick<Shift, "shift_type" | "date" | "nurse_id"> | null;
      } = {};

      isHasData = true;

      shifts?.forEach((item) => {
        const date = dayjs(item.date);
        const dayOfMonth = date.date();
        shiftsData["day" + dayOfMonth] = item;
      });

      dataSubmitArray.forEach((item) => {
        const { isDelete, shiftType, nurseId } = item;
        const date = dayjs(item.date);
        if (!date.isSame(yearMonth, "month")) return;

        const dayOfMonth = date.date();
        if (isDelete) {
          shiftsData["day" + dayOfMonth] = null;
        } else {
          shiftsData["day" + dayOfMonth] = {
            shift_type: shiftType,
            nurse_id: nurseId.toString(),
            date: item.date,
          };
        }
      });

      const newItem = {
        key: id,
        id,
        family_name,
        nurse_code,
        employment_type,
        ...shiftsData,
      };
      result.push(newItem);
    });

    setHasData(isHasData)
    return isHasData ? result : []
  
  }, [data, preDataSubmit]);
  const getNameEmploymentType = (type: string) => {
    return EMPLOYMENT_TYPE_OPTIONS.find(x => x.value === type)?.label || ""
  }
  const columns: TableColumnsType<any> = useMemo(() => {
    return [
      {
        title: <div className="px-4">介護スタッフ</div>,
        dataIndex: "family_name",
        key: "family_name",
        width: 150,
        render: (_: string, record: any) => {
          console.log({record})
          return   <div className="pl-4 pr-2">
          <p>{record.nurse_code}</p>
          <p>{record.family_name}</p>
          <p>{getNameEmploymentType(record.employment_type)}</p>
        </div>
        },
        fixed: "left",
        onCell: (record) => {
          return {
            style: {
              backgroundColor:
                disabled || (isStaff && userId !== record.id) ? "#cccccc" : "",
            },
          };
        },
      },

      ...daysArray.map((item, indexCol) => {
        return {
          title: (
            <div className="">
              <div className="h-6 text-center border-b border-[#010a021f]">
                {item.dayOfMonth}
              </div>
              <div className="h-6 text-center">{item.dayOfWeek}</div>
            </div>
          ),
          dataIndex: "day" + (indexCol + 1),
          key: indexCol,
          width: 28,
          onCell: (record: any) => {
            return {
              style: {
                backgroundColor:
                  disabled || (isStaff && userId !== record.id)
                    ? "rgba(0,0,0,0.2)"
                    : "",
              },
            };
          },
          render: (value: Shift, record: any, index: number) => {
            return (
              <div
                onMouseDown={() => {
                  setNameNurseSelecting(record.family_name);
                  window.getSelection()?.removeAllRanges(); // Clear the text selection on the page
                }}
              >
                <ShiftCard
                  disabled={disabled || (isStaff && index > 0)}
                  indexCol={indexCol}
                  row={index + 1}
                  nurseId={record.id}
                  date={item.date}
                  shiftType={value?.shift_type}
                  id={value?.id}
                />
              </div>
            );
          },
        };
      }),
    ];
  }, [disabled, daysArray]);

  const refTable = useRef<HTMLDivElement | null>(null);
  const handleOnCancelModal = () => {
    setOpenModal(false);

    // cancel selected cell
    if (refTable.current && refPreRowSelected.current) {
      const preRow = refPreRowSelected.current;
      const preRowCells = refTable.current.querySelectorAll(
        ".staff_schedule_row" + preRow
      );
      if (preRowCells)
        preRowCells.forEach((el) => el.classList.remove(CLASS_SELECTED_CELL));
    }
  };

  const handleSaveTmpDataSubmit = (
    dateFormats: string[],
    nurseId: string,
    shiftType: ShiftType,
    isDelete: boolean
  ) => {
    const dataSubmitClone = { ...preDataSubmit };
    const preNurseId = selectedItems[0]?.nurseId
    if (!dataSubmitClone[preNurseId]) dataSubmitClone[preNurseId] = {};
    const dataNew = dateFormats.reduce((accumulator, currentValue) => {
      return {
        ...accumulator,
        [currentValue]: {
          nurseId: preNurseId,
          date: currentValue,
          shiftType: shiftType,
          isDelete,
        },
      };
    }, {});
    
    if(preNurseId !== preNurseId && !isDelete) {
      const dataEffect = Object.values(dataNew).reduce((acc : any, cur: any) => {
        return {
          ...acc,
          [cur.date]: {
            nurseId: preNurseId,
            date: cur.date,
            shiftType: shiftType,
            isDelete: true,
          },
        };
      }, {}) as any
      dataSubmitClone[preNurseId] = { ...dataSubmitClone[preNurseId], ...dataEffect };
    }
    dataSubmitClone[preNurseId] = { ...dataSubmitClone[preNurseId], ...dataNew };
    setPreDataSubmit(dataSubmitClone);
  };

    useEffect(() => {
    if (refTable.current && !disabled) {
      let originalRow: any;
      let original: any;
      let originalLeft: any;
      let isSelecting = false;
      let cellsOnRowSelected: any;
      let positionCellonRowSelected: any[] = [];
      let result: SelectedItem[] = [];

      document.onmousemove = (e) => {
        if (!!originalRow && !!original) {
          result = [];
          const x = e.clientX;
          const isLeft = x < originalLeft;

          positionCellonRowSelected.forEach((element) => {
            if (isLeft) {
              if (
                (element.right > x && element.right < originalLeft) ||
                element.isOriginal
              ) {
                element.element.classList.add(CLASS_SELECTED_CELL);
                result.push({
                  indexCol: element.indexCol,
                  date: element.date,
                  nurseId: element.nurseId,
                  shiftType: element.shiftType,
                  key: uniqueId("init_")
                });
              } else {
                element.element.classList.remove(CLASS_SELECTED_CELL);
              }
            } else {
              if (element.left < x && element.right > originalLeft) {
                element.element.classList.add(CLASS_SELECTED_CELL);
                result.push({
                  indexCol: element.indexCol,
                  date: element.date,
                  nurseId: element.nurseId,
                  shiftType: element.shiftType,
                  key: uniqueId("init_")
                });
              } else {
                element.element.classList.remove(CLASS_SELECTED_CELL);
              }
            }
          });
        }
      };

      window.onmouseup = (e) => {
        if (isSelecting) {
          setSelectedItems(result);
          setOpenModal(true);
          originalRow = null;
          original = null;
        }
        isSelecting = false;
      };

      refTable.current.onmousedown = function (e) {
        if (disabled) return;
        cellsOnRowSelected?.forEach((element: HTMLDivElement) => {
          element.classList.remove(CLASS_SELECTED_CELL);
        });
        const targetEl = e.target as HTMLDivElement;
        const staffScheduleElm = targetEl.closest(
          ".staff_schedule"
        ) as HTMLDivElement;
        if (!staffScheduleElm) return;
        isSelecting = true;
        const row = staffScheduleElm.dataset.row;
        const indexCol = staffScheduleElm.dataset.indexcol || "";
        const date = staffScheduleElm.dataset.date || "";
        const nurseId = staffScheduleElm.dataset.nurseid || "";
        const shiftType = staffScheduleElm.dataset.shifttype || ""

        originalRow = row;
        original = indexCol;
        originalLeft = staffScheduleElm.getBoundingClientRect()?.left;
        staffScheduleElm.classList.add(CLASS_SELECTED_CELL);
        cellsOnRowSelected = refTable?.current?.querySelectorAll(
          ".staff_schedule_row" + row
        );
        refPreRowSelected.current = row;
        result = [
          {
            indexCol,
            date,
            nurseId,
            shiftType: shiftType as ShiftType,
            key: uniqueId("init_")
          },
        ];

        positionCellonRowSelected = Array.from(cellsOnRowSelected)?.map(
          (cell) => {
            const eleCell = cell as HTMLDivElement;
            const { top, right, bottom, left } =
              eleCell.getBoundingClientRect();
            const indexCol = eleCell.dataset.indexcol;
            const date = eleCell.dataset.date;
            const nurseId = eleCell.dataset.nurseid;
            const shiftType = eleCell.dataset.shifttype;

            return {
              top,
              right,
              bottom,
              left,
              indexCol,
              date,
              isOriginal: indexCol === original,
              nurseId,
              element: cell,
              shiftType
            };
          }
        );
      };
    }
    return () => {
      if (refTable.current) refTable.current.onmousedown = null;
      window.onmouseup = null;
      document.onmousemove = null;
    };
  }, [disabled, selectedItems]);

  const handleSubmit = async () => {
    // submit save at BE
   
    try {
      if (isEmpty(preDataSubmit)) {
        await refetch();
        return setDisabled(true);
      }

      setIsSubmitting(true);
      const data: any = [];
      Object.keys(preDataSubmit).map((key) => {
        const result: { [key: string]: any } = {};
        const nurse_id = key;
        const dataItem = preDataSubmit[key];

        Object.values(dataItem).forEach((item) => {
          const shift_type = item.shiftType;
          const isDelete = item.isDelete;
          if (isDelete) {
            if (!result["delete"])
              result["delete"] = {
                dates: [],
                date_deleted: [],
                nurse_id,
                shift_type: SHIFT_TYPES.day_off.value,
              };
            return result["delete"].date_deleted.push(item.date);
          }
          if (!result[shift_type] && !isDelete)
            result[shift_type] = { dates: [] };
          result[shift_type].nurse_id = nurse_id;
          result[shift_type].shift_type = shift_type;
          result[shift_type].dates.push(item.date);
        });
        data.push(...Object.values(result));
      });
      const res = (await api.createOrUpdate({ data })) as any;
      await refetch();
      setPreDataSubmit({});
      toast.success(res.success);
    } catch (e) {
      toast.error("保存が失敗しました。");
    } finally {
      setIsSubmitting(false);
    }
    setDisabled(!disabled);
  };

  const getTextBtn = () => {
    if (isStaff) return disabled ? "自分の予定を編集" : "保存";
    return disabled ? "編集" : "保存";
  };

  const getMaxHeightTable = () => {
    const heightOfTable = window.innerHeight - (refTable.current?.offsetTop || 0)
    if (isStaff) {
      if(windowWidth < 450 && disabled) return "calc(100vh - 277px)"
      if(windowWidth < 450) return "calc(100vh - 312px)"
      if (windowWidth < 1024) return "calc(100vh - 280px)";
      return `${heightOfTable - 140}px`
    }
     return `${heightOfTable - 135}px`
  };

  return (
    <div>
      <div
        className={`page-title ${
          isStaff
            ? `bg-white !m-0 py-[11px] px-6 font-bold ${
                !isMobile && !isTablet && "!mb-4"
              }`
            : ""
        }`}
      >
        シフト管理　休日・出勤申請
      </div>

      <div
        className={`page-container ${
          isStaff ? (isTablet || isMobile ? "m-0" : "mt-3 mx-6") : ""
        }`}
      >
        <div className="w-full bg-white">
          <div
            className={
              isStaff && windowWidth < 450 && !disabled
                ? ""
                : "flex items-center justify-between"
            }
          >
            <DateChanger
              picker="month"
              type="months"
              format={MONTH_FORMAT}
              className="!border-b-0 w-min !p-0"
              onChange={(date) => {
                setYearMonth(date.format(MONTH_FORMAT));
              }}
            />
            <Flex gap="12px">
              {!disabled && (
                <ButtonOutline
                  onClick={() => {
                    setPreDataSubmit({});
                    setDisabled(true);
                  }}
                >
                  キャンセル
                </ButtonOutline>
              )}
              <ButtonSolid
                className="!min-w-[102px]"
                isLoading={isSubmitting}
                isDisabled={!isStaff && !hasData && isEmpty(preDataSubmit)}
                onClick={() => {
                  if (disabled) return setDisabled(false);
                  handleSubmit();
                }}
              >
                {getTextBtn()}
              </ButtonSolid>
            </Flex>
          </div>
          <TableStyled
            loading={isFetching || isLoading}
            ref={refTable}
            tableLayout="fixed"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            className="!rounded-none mt-3"
            bordered
            rowClassName={() => "border-b !p-0"}
            scroll={
              hasData
                ? {
                    x: "max-content",
                    ...{
                      ...(dataSource.length > 1
                        ? { y: getMaxHeightTable() }
                        : {}),
                    },
                  }
                : { x: "max-content" }
            }
          />
          <MolalSchedule
            open={openModal}
            onCancel={handleOnCancelModal}
            selectedItems={selectedItems}
            handleSaveTmpDataSubmit={handleSaveTmpDataSubmit}
            isStaff={isStaff}
            nameNurseSelecting={nameNurseSelecting}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageShiftStaff;
