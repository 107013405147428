import { useState } from "react";
import Form from "./components/Form"; // Form RecipientCertification
import TableList from "./components/TableList"; // Table list RecipientCertification
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TypeStateNavigate } from "./_type";

interface IRecipientCertificationProps {
  isStaff: boolean;
}

const RecipientCertification = ({ isStaff }: IRecipientCertificationProps) => {
  const [inforNavigate, setInforNavigate] =
    useState<TypeStateNavigate["inforNavigate"]>(null);
  return inforNavigate ? (
    <Form
      inforNavigate={inforNavigate}
      setInforNavigate={setInforNavigate}
      isStaff={isStaff}
    />
  ) : (
    <TableList
      setInforNavigate={setInforNavigate}
      inforNavigate={inforNavigate}
      isStaff={isStaff}
    />
  );
};
export default RecipientCertification;
