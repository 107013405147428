import dayjs, { ConfigType, Dayjs } from "dayjs";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "utils/classnames";
import { Styled } from "./styled";
import { DatePicker } from "antd";
import { ChevronLeft, ChevronRight } from "assets/icons";
import { MONTH_FORMAT } from "constant/date";

export const CURRENT_DATE_KEY = "currentDate";

interface MonthChangerProps {
  onChange?: (date: Dayjs) => void;
  className?: string;
}

const MonthChanger = (props: MonthChangerProps) => {
  const { onChange } = props;
  const [searchParams] = useSearchParams();
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());

  const handleNext = () => {
    const newDate = currentDate?.add(1, "months");
    setCurrentDate(newDate);
    onChange?.(newDate);
  };

  const handlePrev = () => {
    const newDate = currentDate.add(-1, "months");
    setCurrentDate(newDate);
    onChange?.(newDate);
  };

  const handleChangeMonth = (date: ConfigType) => {
    setCurrentDate(dayjs(date));
    onChange?.(dayjs(date));
  };

  useEffect(() => {
    const newDate = dayjs(searchParams.get(CURRENT_DATE_KEY)).isValid()
      ? dayjs(searchParams.get(CURRENT_DATE_KEY))
      : dayjs();
    setCurrentDate(newDate);
  }, [searchParams]);

  return (
    <>
      <Styled className={cn("flex py-2", props.className)}>
        <button onClick={handlePrev} className="p-3">
          <ChevronLeft />
        </button>
        <DatePicker
          value={currentDate}
          format={MONTH_FORMAT}
          inputReadOnly={true}
          allowClear={false}
          picker="month"
          onChange={handleChangeMonth}
        />
        <button onClick={handleNext} className={cn("p-3")}>
          <ChevronRight />
        </button>
      </Styled>
    </>
  );
};

export default MonthChanger;
