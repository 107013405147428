import { DatePickerProps } from "antd";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import _isBetween from "dayjs/plugin/isBetween";
import _isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(_isBetween);
dayjs.extend(_isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const JP_DATE_FORMAT = "YYYY[年]MM[月]DD[日]";
export const DATE_FORMAT = "YYYY/MM/DD";
export const DATE_FORMAT_2 = "YYYY-MM-DD";
export const DATE_FORMAT_3 = "MM月 DD日";
export const DATE_FORMAT_4 = "YYYY/MM/DD HH:mm";

export const MONTH_FORMAT = "YYYY/MM";
export const DATE_ERA_FORMAT = "NNNN yy年 MM月 DD日";
export const DATE_ERA_FORMAT_2 = "yy年MM月DD日";
export const DATE_ERA_FORMAT_YEAR_MONTH = "NNNNyy年MM月";
export const DATE_ERA_FORMAT_YEAR = "NNNN";
export const TIME_FORMAT = "HH:mm";
export const AM_PM_TIME_FORMAT = "hh:mm A";

const LIST_OF_DATE_FORMAT = [
  JP_DATE_FORMAT,
  DATE_FORMAT,
  DATE_FORMAT_2,
  MONTH_FORMAT,
  DATE_ERA_FORMAT,
  TIME_FORMAT,
] as const;

export const DAYS_IN_WEEK = ["日", "月", "火", "水", "木", "金", "土"];

export type TDateFormat = (typeof LIST_OF_DATE_FORMAT)[number];

// use with antd Datepicker "format" prop
export const JP_DATEPICKER_FORMAT: DatePickerProps["format"] = (date) =>
  dayjs(date).toDate().toLocaleDateString("ja-JP-u-ca-japanese", {
    era: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

// function to convert string to jp date string
export const formatJapanDate = (
  date: string,
  optionalParams?: Intl.DateTimeFormatOptions
) =>
  new Date(date).toLocaleDateString("ja-JP-u-ca-japanese", {
    era: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
    ...optionalParams,
  });

/**
 * This function takes a current date and returns true if it is in the past.
 * @param current - The `current` parameter is a variable representing the current date and time,
 * typically in the form of a `dayjs` object.
 */
export const disablePastDates = (current: ReturnType<typeof dayjs>) => {
  return current && dayjs(current).isSameOrBefore(dayjs().add(-1, "days"));
};

/**
 * This function takes a Date object or string and a format string as input, validates the
 * date, and returns the formatted date string.
 * @param {Date | string} date - The `date` parameter in the `formatDate` function can accept either a
 * `Date` object or a string representing a date.
 * @param {TDateFormat} [format=YYYY/MM/DD] - The `format` parameter in the `formatDate` function is
 * used to specify the desired format in which the date should be formatted.
 */
export const formatDate = (
  date: Date | string,
  format: TDateFormat = "YYYY/MM/DD"
) => {
  if (!dayjs(date).isValid()) {
    return "";
  }

  return dayjs(date).format(format);
};

export const HAFT_HOUR = 30;
export const HOURS_PER_DAY = 24;
export const JAPANESE_ERA_YEARS = [
  "令和", //Reiwa 5/2019 - now
  "平成", //Heisei  1989 – 2019
  "昭和", //Shōwa  1926–1989
  "大正", //Taishō  1912–1926
  "明治", //Meiji  1868–1912
  "慶応", //Keiō  1865—1868
  "元治", //Genji  1864—1865
  "文久", //Bunkyū  1861—1864
  "万延", //Man’en  1860—1861
  "安政", //Ansei  1854—1860
  "嘉永", //Kaei  1848—1854
  "弘化", //Kōka  1844—1848
];

