import styled from "@emotion/styled";

export const Styled = styled.div`
  * {
    word-break: break-word;
  }
`;

export const TablelStyled = styled.table`
  & {
    width: 100%;
  }

  td {
    padding: 0 5mm;
  }

  &,
  th,
  td {
    border: 1mm solid black;
  }
`;
