import { Button, Form, Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import TableAdmin from "component/admin/table";
import { ButtonSolid } from "component/button";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Styles from "./styled";
import _, { forEach, get, omit } from "lodash";
import { toast } from "react-toastify";
import { useWatch } from "antd/es/form/Form";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { TypeRecipient, TypeResListRecipient } from "../../_type";

import ModalConfirmDelete from "component/ModalConfirmDelete";
import { formatNumber } from 'utils/formats'
import { TypeStateNavigate } from '../../_type'
import { SERVICE_CONTENTS } from "constant";

type TypeProps = {
  isStaff: boolean
} & TypeStateNavigate


const RecipientCertificationTable = ({inforNavigate, setInforNavigate, isStaff }: TypeProps) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const page = useWatch("page", form);
  const per = useWatch("per", form);
  const [dataSource, setDataSource] = useState<TypeRecipient[]>([]);
  const [idsDelete, setIdsDelete] = useState<{ [key: string]: boolean }>({});
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const handleCheckDelete = (isDelete: boolean, id: number) => {
    const newIdsDelete = { ...idsDelete };
    if (newIdsDelete.hasOwnProperty(id) && !isDelete) {
      setIdsDelete(omit(newIdsDelete, id));
      return;
    }
    newIdsDelete[id] = isDelete;
    setIdsDelete(newIdsDelete);
  };

  const handleCheckAllDelete = (checked: boolean) => {
    const newIdsDelete: { [key: string]: boolean } = {};
    if (checked) {
      forEach(dataSource, (item) => {
        newIdsDelete[item.id] = true;
      });
    }
    setIdsDelete(newIdsDelete);
  };

  const columns: ColumnsType<TypeRecipient> = [
    {
      align: "center",
      title: (
        <div>
          <Checkbox
            disabled={isStaff}
            checked={
              Object.keys(idsDelete).length === dataSource.length &&
              !!dataSource.length
            }
            onChange={(e) => handleCheckAllDelete(e.target.checked)}
          />
          <span className="ml-2">選択</span>
        </div>
      ),
      width: 94,
      onHeaderCell: (column) => {
        return {
          style: { fontWeight: "bold" }, // Custom styles
          onClick: () => {},
        };
      },
      render: (value, record) => (
        <Checkbox
          disabled={isStaff}
          checked={idsDelete[value.id]}
          onChange={(e) => {
            handleCheckDelete(e.target.checked, record.id);
          }}
        />
      ),
    },
    {
      align: "center",
      title: "サービス内容",
      width: 107,
      dataIndex: "service_content",
      render: (value) => {
        const ketAs = value as keyof typeof SERVICE_CONTENTS
        let textValue = ""
        if(value.trim()) {
          textValue = SERVICE_CONTENTS[ketAs] || "" //#101983
        }
        return (
          <>
            {_.truncate(textValue, {
              length:
                textValue && textValue.length > 20 && textValue.length < 23
                  ? textValue.length + 3
                  : 23,
              // omission:
            })}
          </>
        );
      },
      // sorter: true,
    },
    {
      align: "center",
      title: "契約支給量",
      dataIndex: "contract_allocation_amount",
      width: 101,
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "契約日",
      dataIndex: "contract_date",
      width: 67,
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "サービス提供終了日",
      width: 147,
      dataIndex: "service_end_date",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "適用開始年月日",
      width: 129,
      dataIndex: "applicable_start_date",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "利用者負担割合(原則)",
      width: 158,
      dataIndex: "user_burden_percentage",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "負担上限月額",
      width: 105,
      dataIndex: "monthly_cost_limit",
      render: (value) => (
        <>
          {_.truncate(formatNumber(value), {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "上限管理事務所",
      width: 124,
      //dataIndex: "recipient_code",
    },
    {
      align: "center",
      title: "操作",
      dataIndex: "action",
      width: 62,
      ...{ ...(isStaff ? { fixed: "right" } : {}) },
      render: (_, record) => (
        <div className="wrapper-action justify-center">
          <ButtonSolid
            width={46}
            onClick={() =>
              setInforNavigate({
                ...inforNavigate,
                recipientId: record.id,
                action: "edit",
              })
            }
          >
            詳細
          </ButtonSolid>
        </div>
      ),
    },
  ];
  const service = useService();
  const fetchData = async () => {
    setIsLoading(true);
    setIdsDelete({});
    try {
      const filter = form.getFieldsValue();
      const res = await axiosClient({
        method: "get",
        url: service.RECIPIENTS,
        params: {...filter, patient_id: id},
      });
      const data = res.data as TypeResListRecipient;
      if (data.recipients) setDataSource(data.recipients);
      if (data.recipients.length === 0 && page > 1) {
        form.setFieldValue("page", page - 1);
        form.submit();
      }
      setIdsDelete({});
      const newTotal = get(data, "total_items", 0);
      setTotal(newTotal);
    } catch (error) {
      toast.error("Error");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleOkDelete = async () => {
    setIsDeleting(true);
    const idsArrDelete = Object.keys(idsDelete);
    try {
      const res = await axiosClient({
        method: "post",
        url: `${service.RECIPIENTS}/delete-multiple`,
        data: { ids: idsArrDelete },
      });
      const newTotal = get(res.data, "total_items", 0);
      if (Math.ceil(newTotal / per) < page && page > 1) {
        form.setFieldValue("page", page - 1);
      }
      toast.success(get(res.data, "message", ''));
      setIsOpenModalDelete(false);
      fetchData();
    } catch (e) {
      console.log(e);
    } finally {
      setIsDeleting(false);
    }
  };


  return (
    <>
      <Styles>
        {!isStaff && (
          <div className="text-end">
            <ButtonSolid
              isDisabled={!Object.keys(idsDelete).length}
              className="mr-6 !bg-[#D83535] hover:!bg-[#bb2323]"
              onClick={() => setIsOpenModalDelete(true)}
            >
              削除
            </ButtonSolid>
            <ButtonSolid
              width={106}
              onClick={() =>
                setInforNavigate({
                  ...inforNavigate,
                  recipientId: null,
                  action: "edit",
                })
              }
            >
              新規登録
            </ButtonSolid>
          </div>
        )}
        <div className="overflow-x-auto">
          <Form form={form} onFinish={() => fetchData()}>
            <TableAdmin
              form={form}
              data={dataSource}
              columns={columns}
              loading={isLoading || isDeleting}
              total={total}
              rowClassName="custom-row"
              onHeaderRow={() => ({ className: "custom-row" })}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
                showTotal: () => (
                  <div>
                    {(page - 1) * per + 1} ~
                    {page * per > total ? total : page * per}
                    件(全{total}件中)
                  </div>
                ),
                showQuickJumper: true,
                total,
                pageSize: per,
                current: page,
                pageSizeOptions: [10, 20, 50, 100],
              }}
            />
          </Form>
        </div>
      </Styles>

      <ModalConfirmDelete
        open={isOpenModalDelete}
        onCancel={() => setIsOpenModalDelete(false)}
        onOk={handleOkDelete}
        okButtonProps={{ loading: isDeleting }}
        centered
      />
    </>
  );
};

export default RecipientCertificationTable;
