import { ClassNames } from "@emotion/react";

interface IDotProps {
  size: string;
  className?: string;
  color?: string;
}
const DotSymboi = ({ size, className, color = "black" }: IDotProps) => {
  return (
    <div
      className={`rounded-[100%] ${className ? className : ""}`}
      style={{
        height: size,
        width: size,
        background: color,
      }}
    ></div>
  );
};

export default DotSymboi;