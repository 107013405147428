import styled from "@emotion/styled";

export const TablelStyled = styled.table`
  * {
    boder-color: black;
    word-break: break-word;
  }
  & {
    width: 100%;
    border: 1mm solid black;
  }

  th,
  td {
    border: 0.5mm solid;
    border: 0.5mm solid;
    border-bottom: 1mm solid;
  }
`;

export const Styled = styled.div`
  * {
    border-color: black;
  }
  .row-table > div {
    border-right: 0.3mm solid black;
    border-bottom: 0.3mm solid black;
    padding: 0.5mm;
    display: flex;
    align-items: center;
  }
`;
