import { Col, Form, Row } from "antd";
import apiFactory from "api";
import Input from "component/admin/input";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function ProfileDetail() {
  const [form] = Form.useForm();
  const [data, setData] = useState<{
    id: number | null;
    facility: string;
    postCode: string;
    prefectures: string;
    municipalities: string;
    street: string;
    apartment: string;
    phone: string;
    fax: string;
    business_number: string;
    grade_registration: string;
    rate_registration: string;
  }>({
    id: null,
    facility: "",
    postCode: "",
    prefectures: "",
    municipalities: "",
    street: "",
    apartment: "",
    phone: "",
    fax: "",
    business_number: "",
    grade_registration: "",
    rate_registration: "",
  });
  const onFinish = async (values: any) => {
    console.log('call')
  };

  const fetchData = async () => {
    try {
      const result = await apiFactory.profileAdminApi.getServiceDetail()
      if (result) {
        form.setFieldsValue({
          id: result.id,
          facility: result.facility_name,
          postCode: result.zipcode,
          prefectures: result.district,
          municipalities: result.city,
          street: result.street,
          apartment: result.building_name,
          phone: result.mobile_phone,
          fax: result.fax,
          business_number: result.business_number,
          grade_registration: result.grade_registration,
          rate_registration: result.rate_registration,
        });
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div>
      <div className="page-title">管理メニュー　プロフィール</div>
      <div className="page-container">
        {/* <div className="text-end mb-[30px]">
          <ButtonSolid className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]">
            編集
          </ButtonSolid>
        </div> */}
        <Form
          initialValues={data}
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={11}>
              <Form.Item label="施設ID" name="id">
                <Input disabled />
              </Form.Item>
              <Form.Item label="施設名" name="facility">
                <Input disabled />
              </Form.Item>
              <Form.Item label="郵便番号" name="postCode">
                <Input disabled />
              </Form.Item>
              <Form.Item label="都道府県" name="prefectures">
                <Input disabled />
              </Form.Item>
              <Form.Item label="市区町村" name="municipalities">
                <Input disabled />
              </Form.Item>
              <Form.Item label="町名・番地" name="street">
                <Input disabled />
              </Form.Item>
              <Form.Item label="ビル・マンション名" name="apartment">
                <Input disabled />
              </Form.Item>
              <Form.Item label="電話番号" name="phone">
                <Input disabled />
              </Form.Item>
              <Form.Item label="FAX" name="fax">
                <Input disabled />
              </Form.Item>
              <Form.Item label="事業者番号" name="business_number">
                <Input disabled />
              </Form.Item>
              <Form.Item label="等級登録" name="grade_registration">
                <Input disabled />
              </Form.Item>
              <Form.Item label="掛け率登録" name="rate_registration">
                <Input disabled />
              </Form.Item>
             
            </Col>
          </Row>
        </Form>
        {/* <div className="flex items-center justify-between">
          <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
          <div className="flex gap-x-[8px]">
            <ButtonOutline className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]">
              キャンセル
            </ButtonOutline>
            <ButtonSolid className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]">
              保存
            </ButtonSolid>
          </div>
        </div> */}
      </div>
    </div>
  );
}
