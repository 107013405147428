import {useRef, useState, useEffect} from 'react'

const DiagonalLine = ({
  rowSpan,
  colSpan,
}: {
  rowSpan?: number;
  colSpan?: number;
}) => {
  const tdRef = useRef<HTMLTableCellElement>(null);
  const [size, setSize] = useState<{ height: number; width: number } | null>(
    null
  );
  useEffect(() => {
    if (tdRef.current) {
      const height = tdRef.current.offsetHeight;
      const width = tdRef.current.offsetWidth;
      setSize({
        height,
        width
      })
    }
  }, []);
  return (
    <td rowSpan={rowSpan} colSpan={colSpan} ref={tdRef} style={{...size}}>
        {size && <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
        <line
          x1="100%"
          y1="0"
          x2="0"
          y2="100%"
          style={{
            stroke: "black",
            strokeWidth: "1px",
          }}
        />
      </svg>} 
    </td>
  );
};

export default DiagonalLine;
