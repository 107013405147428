import { useRef, useState, useLayoutEffect } from "react";
import { IReactToPrintProps, useReactToPrint } from "react-to-print";

const usePrint = (isHidden: Boolean = false, propsPrint?: Omit<IReactToPrintProps, "content">) => {
  //isHidden = true nếu như element mặc định không hiển thị, false nếu như element mặc định hiển thị
  const ref = useRef<HTMLDivElement>(null);
  const [isCapturing, setCapturing] = useState(false);
  const print = useReactToPrint({
    ...propsPrint,
    content: () => ref.current,
  });
  const handlePrint = () => {
    if (ref.current) {
      ref.current.hidden = false;
      print();
      if (isHidden) ref.current.hidden = true;
    }
  };
  useLayoutEffect(() => {
    if (isCapturing) {
      handlePrint();
      setCapturing(false);
    }
  }, [isCapturing]);
  return { ref, handlePrint, isCapturing, setCapturing };
};
export default usePrint;
